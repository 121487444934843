// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-all-menus-js": () => import("./../../../src/pages/dashboard/all-menus.js" /* webpackChunkName: "component---src-pages-dashboard-all-menus-js" */),
  "component---src-pages-dashboard-category-js": () => import("./../../../src/pages/dashboard/category.js" /* webpackChunkName: "component---src-pages-dashboard-category-js" */),
  "component---src-pages-dashboard-dish-js": () => import("./../../../src/pages/dashboard/dish.js" /* webpackChunkName: "component---src-pages-dashboard-dish-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-dashboard-restaurant-js": () => import("./../../../src/pages/dashboard/restaurant.js" /* webpackChunkName: "component---src-pages-dashboard-restaurant-js" */),
  "component---src-pages-dashboard-settings-js": () => import("./../../../src/pages/dashboard/settings.js" /* webpackChunkName: "component---src-pages-dashboard-settings-js" */),
  "component---src-pages-dashboard-table-js": () => import("./../../../src/pages/dashboard/table.js" /* webpackChunkName: "component---src-pages-dashboard-table-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-register-contact-info-js": () => import("./../../../src/pages/register/contact-info.js" /* webpackChunkName: "component---src-pages-register-contact-info-js" */),
  "component---src-pages-register-restaurant-details-js": () => import("./../../../src/pages/register/restaurant-details.js" /* webpackChunkName: "component---src-pages-register-restaurant-details-js" */),
  "component---src-pages-register-review-js": () => import("./../../../src/pages/register/review.js" /* webpackChunkName: "component---src-pages-register-review-js" */)
}

