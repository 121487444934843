export const Colors = {
  ORANGE: "#F06441",
  ORANGE_LIGHT: "#F48B71",
  ORANGE_LIGHTEST: "#FBD8D0",
  SLATE: "#8A9DB7",
  SLATE_LIGHT: "#E1E7EC",
  SLATE_LIGHTER: "#F0F2F7",
  SLATE_DARK: "#627083",
  GRAY: "#939393",
  BLUE: "#628DEB",
  BLUE_LIGHT: "#D6E2FA",
  RED: "#FA3838",
  RED_LIGHT: "#FB6565",
  WHITE: "#FFFFFF",
  BLACK75: "#606060",
};
